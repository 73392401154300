import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-mattress-firm-component-library-️"
    }}>{`⚛️ Mattress Firm Component Library ⚛️`}</h1>
    <h2 {...{
      "id": "-getting-started"
    }}>{`🚀 Getting started`}</h2>
    <ol>
      <li parentName="ol">{`Install NodeJS. Use a node version manager such as nvm. Currently this repo targets Node 18.15.0`}</li>
      <li parentName="ol">{`Install yarn.`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn`}</inlineCode>{` to install dependencies`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn dev`}</inlineCode>{` to start development mode`}</li>
    </ol>
    <h3 {...{
      "id": "documentation"
    }}>{`Documentation`}</h3>
    <p>{`Checkout the `}<a parentName="p" {...{
        "href": "https://mfcl.uat.mfrm.com/"
      }}>{`component library in action`}</a>{`!`}</p>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <ul>
      <li parentName="ul">{`Follow the steps in the `}<a parentName="li" {...{
          "href": "https://projects-mfrm.visualstudio.com/Mattress-Firm/_wiki/wikis/Mattress-Firm.wiki/583/MFCL-Setup-and-Installation"
        }}>{`wiki`}</a>{` to learn how to setup and install this package`}</li>
      <li parentName="ul">{`If you've already setup your machine to install this package, then run `}<inlineCode parentName="li">{`yarn add @mfrm/mfcl`}</inlineCode>{` in your terminal`}</li>
    </ul>
    <h3 {...{
      "id": "-usage"
    }}>{`👾 Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React, { Component } from 'react'
import MyComponent from '@mfrm/mfcl/MyComponent'

...

<MyComponent prop="yes please" />
`}</code></pre>
    <ul>
      <li parentName="ul">{`Note - webpack should automatically treeshake the components not used.`}</li>
    </ul>
    <h2 {...{
      "id": "-developer-experience"
    }}>{`✨ Developer experience`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`yarn start`}</inlineCode>{` or `}<inlineCode parentName="li">{`yarn dev`}</inlineCode>{` - Starts the documentation at http://localhost:4000/`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn watch`}</inlineCode>{` - rollup watches any changes (not really needed)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn package`}</inlineCode>{` - builds the component library`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn build:docz`}</inlineCode>{` - builds the docs`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn test`}</inlineCode>{` - runs any tests`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn plop`}</inlineCode>{` - see below`}</li>
    </ul>
    <h2 {...{
      "id": "-style-management"
    }}>{`💅 Style management`}</h2>
    <p>{`CSS Modules`}</p>
    <h3 {...{
      "id": "docz"
    }}>{`Docz`}</h3>
    <p>{`The docs are built with ❤️ from `}<a parentName="p" {...{
        "href": "https://www.docz.site/"
      }}>{`docz`}</a></p>
    <h3 {...{
      "id": "create-a-new-component-with-plop-component-generator"
    }}>{`Create a new component with Plop Component Generator`}</h3>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`yarn plop`}</inlineCode>{` and follow the prompt or `}<inlineCode parentName="li">{`yarn plop MyComponent`}</inlineCode>{` will generate `}<inlineCode parentName="li">{`MyComponent.jsx`}</inlineCode>{`, `}<inlineCode parentName="li">{`myComponent.module.scss`}</inlineCode>{`, `}<inlineCode parentName="li">{`MyComponent.test.js`}</inlineCode>{`, and `}<inlineCode parentName="li">{`MyComponent.mdx`}</inlineCode></li>
      <li parentName="ol">{`Update `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` according to semver.`}</li>
      <li parentName="ol">{`Give a description of what changed in `}<inlineCode parentName="li">{`CHANGELOG.mdx`}</inlineCode>{` followed by who made the changes.`}</li>
      <li parentName="ol">{`Profit 💰`}</li>
    </ol>
    <h3 {...{
      "id": "automatic-code-formatting"
    }}>{`Automatic code formatting`}</h3>
    <p><a parentName="p" {...{
        "href": "https://prettier.io/"
      }}>{`prettier`}</a>{` is an opinionated code formatter aiming to provide codebase consistency when multiple developers work on the same project. The main reason behind adopting Prettier is to `}<a parentName="p" {...{
        "href": "https://prettier.io/docs/en/why-prettier.html"
      }}>{`stop all the on-going debates over coding styles`}</a>{`.`}</p>
    <h3 {...{
      "id": "linting"
    }}>{`Linting`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Lint_(software)"
      }}>{`Linters`}</a>{` are tools that analyze source code to flag programming errors, bugs, stylistic errors, and suspicious constructs.`}</p>
    <ul>
      <li parentName="ul">{`JavaScript is linted by `}<a parentName="li" {...{
          "href": "https://eslint.org/"
        }}>{`ESLint`}</a>{`, enforcing the `}<a parentName="li" {...{
          "href": "https://github.com/airbnb/javascript"
        }}>{`Airbnb JavaScript Style Guide`}</a>{` through an overridable set of rules provided by `}<a parentName="li" {...{
          "href": "https://github.com/airbnb/javascript/tree/master/packages/eslint-config-airbnb-base"
        }}>{`eslint-config-airbnb-base`}</a>{`.`}</li>
      <li parentName="ul">{`Styles are linted by `}<a parentName="li" {...{
          "href": "https://stylelint.io/"
        }}>{`stylelint`}</a>{`, adhering to the rules specified in `}<a parentName="li" {...{
          "href": "https://github.com/stylelint/stylelint-config-recommended"
        }}>{`stylelint-config-recommended`}</a>{`.`}</li>
    </ul>
    <hr></hr>
    {/* LINKS */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      